import React, { useRef } from 'react'

import { getDynamicMegaMenuClasses } from '~/utils/navigation'

import {
  MegaMenuPanel,
  MegaListGrid,
  MegaListGridColumn,
  MegaList,
  MegaListItem,
  MegaListTitle,
  MegaLink,
} from '~/styles/navigation'

const ShopMenu = ({ isActive }) => {
  const title = 'Shop'
  const menuRef = useRef(null)

  return (
    <MegaMenuPanel
      id={`${title}-panel`}
      aria-labelledby={`${title}-button`}
      className={`${getDynamicMegaMenuClasses(isActive)} py-7`}
    >
      <MegaListGrid className="max-w-[1300px] grid-cols-5">
        <MegaListGridColumn>
          <MegaList>
            <MegaListItem className="mb-0 font-bold">
              <MegaListTitle>Occasions</MegaListTitle>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/mens/wedding-suits/">
                Men's Wedding Suits
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/mens/wedding-tuxedos/">
                Men's Wedding Tuxedos
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/womens/wedding-suits/">
                Women's Wedding Suits
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/womens/wedding-tuxedos/">
                Women's Wedding Tuxedos
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/all/prom-suits-tuxedos/">
                Prom & Homecoming
              </MegaLink>
            </MegaListItem>

            <MegaListItem className="pt-5 mb-0 font-bold">
              <MegaListTitle>SuitShop Specials</MegaListTitle>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/made-to-order/">Made-to-Order Suiting</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/sale/">50% Off Sale</MegaLink>
            </MegaListItem>
          </MegaList>
        </MegaListGridColumn>

        <MegaListGridColumn>
          <MegaList>
            <MegaListItem className="mb-0 font-bold">
              <MegaLink to="/collections/all-suits-tuxedos/" ref={menuRef}>
                Suits &amp; Tuxedos
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/mens/suits-and-tuxedos/">
                Men's Suits & Tuxedos
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/mens/suits/">Men's Suits</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/mens/tuxedos/">Men's Tuxedos</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/womens/suits-and-tuxedos/">
                Women's Suits & Tuxedos
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/womens/suits/">Women's Suits</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/womens/tuxedos/">
                Women's Tuxedos
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/kids/">Kids &amp; Toddlers</MegaLink>
            </MegaListItem>
          </MegaList>
        </MegaListGridColumn>

        <MegaListGridColumn>
          <MegaList>
            <MegaListItem className="mb-0 font-bold">
              <MegaListTitle>Separates</MegaListTitle>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/mens-suit-jackets/">
                Men's Jackets
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/mens-dress-pants/">
                Men's Pants
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/womens-blazers/">
                Women's Blazers
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/womens-dress-pants/">
                Women's Pants
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/unisex-suit-jackets/">
                Unisex Jackets
              </MegaLink>
            </MegaListItem>
          </MegaList>
        </MegaListGridColumn>

        <MegaListGridColumn>
          <MegaList>
            <MegaListItem className="mb-0 font-bold">
              <MegaLink to="/collections/all-accessories/">
                Accessories
              </MegaLink>
              <MegaListTitle>&nbsp;</MegaListTitle>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/ties/">Ties &amp; Bowties</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/vests/">Suit Vests</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/shirts/">White Dress Shirts</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/shoes/">Dress Shoes</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/belts/">Leather Belts</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/suspenders/">Suspenders</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/socks/">Socks</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/tie-bars/">Tie Bars</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/cufflinks/">Cufflinks</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/pocket-squares/">
                Pocket Squares
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/cummerbunds/">Cummerbunds</MegaLink>
            </MegaListItem>
          </MegaList>
        </MegaListGridColumn>

        <MegaListGridColumn>
          <MegaList>
            <MegaListItem className="mb-0 font-bold">
              <MegaLink to="/collections/groomsman-gifts/">
                Gifts & Extras
              </MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/watches/">Watches</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/wallets/">Leather Wallets</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/whiskey/">Whiskey</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/collections/flasks/">Hand Flasks</MegaLink>
            </MegaListItem>
            <MegaListItem>
              <MegaLink to="/products/halfday-garment-duffel/">
                Halfday Garment Bag
              </MegaLink>
            </MegaListItem>
          </MegaList>
        </MegaListGridColumn>
      </MegaListGrid>
    </MegaMenuPanel>
  )
}

export default ShopMenu
